import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import _ from "lodash";

class DauMetricsCategories extends Component {
  render() {
    const { categories } = this.props;

    const customOrder = ["clicks", "install"];

    const categoriesSorted = _.chain(categories)
      .orderBy(
        [
          (category) => {
            const index = customOrder.indexOf(category.slug);
            return index === -1 ? Infinity : index;
          },
          "slug",
        ],
        ["asc", "asc"]
      )
      .value();
    return (
      <div className="animated fadeIn">
        <Table
          responsive
          size="sm"
          style={{ display: "table-caption", textAlign: "center" }}
        >
          <thead>
            <tr>
              {categoriesSorted.map((item) => (
                <th key={item.slug} width="80px">
                  <span
                    style={{
                      writingMode: "vertical-lr",
                      //display: "inline-block",
                      // height: "200px",
                      // width: "20px",
                    }}
                  >
                    {item.slug + " ($)"}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {categoriesSorted.map((item) => (
                <td key={item.slug} style={{ textAlign: "left" }}>
                  {item.units}
                </td>
              ))}
            </tr>
            <tr>
              {categoriesSorted.map((item) => (
                <td key={item.slug} style={{ textAlign: "left" }}>
                  {item?.daily_percentage
                    ? item?.daily_percentage?.toFixed(2) + "%"
                    : ""}
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.metrics.metrics.loading,
  };
}

export default connect(mapStateToProps)(DauMetricsCategories);
