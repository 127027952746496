import React, { useState } from "react";
import CollapsibleRow from "./CountryCollapsibleRow";

const TableRow = ({ country, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    if (country !== "TOTAL") {
      setIsOpen(!isOpen);
    }
  };

  const summary = details.reduce(
    (acc, item) => {
      acc.users += item.users || 0;
      acc.revenue += item.revenue || 0;
      acc.revenuePerc += item.revenuePerc || 0;
      acc.cost += item.cost || 0;
      acc.costPerc += item.costPerc || 0;
      acc.profit += item.profit || 0;
      acc.profitPerc += item.profitPerc || 0;
      acc.rewardscost += item.rewardscost || 0;
      acc.rewardscostPerc += item.rewardscostPerc || 0;
      return acc;
    },
    {
      users: 0,
      revenue: 0,
      revenuePerc: 0,
      cost: 0,
      costPerc: 0,
      profit: 0,
      profitPerc: 0,
      rewardscost: 0,
      rewardscostPerc: 0,
    }
  );

  return (
    <>
      <tr onClick={handleToggle}>
        <td style={{ textAlign: "left", fontWeight: "bold" }}>
          {country !== "TOTAL" && (
            <span
              className={
                "ml-2 collapsed-arrow " +
                (isOpen ? "collapsedArrowShow" : "collapsedArrowHide")
              }
            ></span>
          )}
          &nbsp;{country}
        </td>
        <td>{summary.users.toFixed(0)}</td>
        <td>{summary.revenue.toFixed(1)}</td>
        <td>{summary.revenuePerc.toFixed(1)}</td>
        <td>{summary.cost.toFixed(1)}</td>
        <td>{summary.costPerc.toFixed(1)}</td>
        <td>{summary.rewardscost.toFixed(1)}</td>
        <td>{summary.rewardscostPerc.toFixed(1)}</td>
        <td>{summary.profit.toFixed(1)}</td>
        <td>{summary.profitPerc.toFixed(1)}</td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan="10">
            <CollapsibleRow details={details} />
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
