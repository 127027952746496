import axios from "axios";
import { showMessage } from "app/store/actions/tapchamps";
import csvDownload from "json-to-csv-export";
import * as TableActions from "app/containers/Users/store/actions";

export const SET_USER_TAB = "[USER] SET TAB";
export const SELECT_USER = "[USER] SELECT USER";
export const FETCH_USER_PROFILE = "[USER] FETCH PROFILE";
export const FETCH_USER_FACE = "[USER] FETCH FACE";
export const FETCH_USER_SORT = "[USER] FETCH SORT";
export const RESET_USER_SORT = "[USER] RESET SORT";
export const BAN_USER = "[USER] BAN USER";
export const UNDELETE_USER = "[USER] UNDELETE USER";
export const REQUIRESMS_USER = "[USER] REQUIRE SMS";
export const REQUIREFACE_USER = "[USER] REQUIRE FACE";
export const UNBAN_USER = "[USER] UNBAN USER";
export const ENABLENOTIFICATIONS_USER = "[USER] ENABLENOTIFICATIONS";
export const DISABLENOTIFICATIONS_USER = "[USER] DISABLENOTIFICATIONS";
export const FRAUDCHECKED_USER = "[USER] FRAUDCHECKED USER";
export const SEND_EMAIL = "[USER] SEND EMAIL";
export const CREDIT_USER = "[USER] CREDIT USER";
export const SET_MANAGER = "[USER] SET_MANAGER";
export const SELECT_REWARD = "[USER] SELECT REWARD";
export const APPROVE_REWARD = "[USER] APPROVE REWARD";
export const REJECT_REWARD = "[USER] REJECT REWARD";
export const CANCEL_REWARD = "[USER] CANCEL REWARD";
export const UNARCHIVE_USER = "[USER] UNARCHIVE USER";

export function creditUser(form) {
  return (dispatch, getState) => {
    const { units } = form;
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
      auth: {
        user: {
          data: { email },
        },
      },
    } = getState();
    const adminEmail = email;
    const request = axios.post("/api/user/credit", {
      userid,
      units,
      adminEmail,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `${username} has been credited with ${units} units`,
        })
      );
      return dispatch({
        type: CREDIT_USER,
        payload: { ...response.data },
      });
    });
  };
}

export function setUserManager(userid, set, username) {
  return (dispatch, getState) => {
    const request = axios.post("/api/user/setmanager", {
      userid,
      set,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `${username} has been ${
            set === true ? "set" : "unset"
          } as manager`,
        })
      );
      return dispatch({
        type: SET_MANAGER,
        payload: { ...response.data },
      });
    });
  };
}

export function sendEmail(form) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/sendemail", {
      userid,
      ...form,
    });
    return request.then((response) => {
      dispatch(
        showMessage({ message: `Email to user "${username}" has been sent` })
      );
      return dispatch({
        type: SEND_EMAIL,
      });
    });
  };
}

export function undeleteUser() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/undelete", {
      userid,
    });
    return request.then((response) => {
      dispatch(showMessage({ message: `User ${username} has been undeleted` }));
      return dispatch({
        type: UNDELETE_USER,
      });
    });
  };
}

export function banUser() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/ban", {
      userid,
    });
    return request.then((response) => {
      dispatch(showMessage({ message: `User ${username} has been banned` }));
      return dispatch({
        type: BAN_USER,
      });
    });
  };
}
export function approveReward(rewardId) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          reward: { id },
        },
      },
    } = getState();
    const request = axios.post("/api/reward/approve", {
      id: rewardId,
      onlyOne: true,
    });
    return request.then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      dispatch(showMessage({ message: `Reward has been approved` }));
      dispatch({
        type: APPROVE_REWARD,
        payload: { id },
      });
      dispatch(fetchUserProfile());
      return dispatch(TableActions.fetch("userrewards"));
    });
  };
}

export function rejectReward(rewardId, reason) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          reward: { id },
        },
      },
    } = getState();
    const request = axios.post("/api/reward/reject", {
      id: rewardId,
      reason,
    });
    return request.then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      dispatch(showMessage({ message: `Reward has been Rejected` }));
      dispatch({
        type: REJECT_REWARD,
        payload: { id },
      });
      dispatch(fetchUserProfile());
      return dispatch(TableActions.fetch("userrewards"));
    });
  };
}

export function cancelReward(rewardId, reason) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          reward: { id },
        },
      },
    } = getState();
    const request = axios.post("/api/reward/cancel", {
      id: rewardId,
      reason,
    });
    return request.then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      dispatch(showMessage({ message: `Reward has been canceled` }));
      dispatch({
        type: CANCEL_REWARD,
        payload: { id },
      });
      dispatch(fetchUserProfile());
      return dispatch(TableActions.fetch("userrewards"));
    });
  };
}

export function requireSms() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/requiresms", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `SMS verification required from user ${username}`,
        })
      );
      return dispatch({
        type: REQUIRESMS_USER,
      });
    });
  };
}

export function requireFace() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/requireface", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Face verification required from user ${username}`,
        })
      );
      return dispatch({
        type: REQUIREFACE_USER,
      });
    });
  };
}

export function unbanUser(fraudshield_disabled) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/unban", {
      userid,
      fraudshield_disabled,
    });
    return request.then((response) => {
      dispatch(showMessage({ message: `User ${username} has been un-banned` }));

      return dispatch(fetchUserProfile());
    });
  };
}

export function setWhitelisted(isWhitelisted) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/setWhitelisted", {
      userid,
      isWhitelisted,
    });
    return request.then((response) => {
      let message = isWhitelisted
        ? `User ${username} has been whitelisted`
        : `User ${username} has been removed from whitelist`;
      dispatch(showMessage({ message: message }));

      return dispatch(fetchUserProfile());
    });
  };
}

export function setSuspicious(isSuspicious) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/setsuspicious", {
      userid,
      isSuspicious,
    });
    return request.then((response) => {
      if (response.data.status === 400) {
        dispatch(
          showMessage({
            message: response.data.error,
            type: "error",
          })
        );
        return;
      }
      dispatch(
        showMessage({
          message: `User ${username} has been un-marked as suspicious`,
        })
      );
      return dispatch(fetchUserProfile());
    });
  };
}

export function deletePaypalEmail() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/deletepaypalemail", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Paypal Email was deleted for user ${username}`,
        })
      );

      return dispatch(fetchUserProfile());
    });
  };
}

export function deletePhone() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/deletephone", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Phone number was deleted for user ${username}`,
        })
      );

      return dispatch(fetchUserProfile());
    });
  };
}

export function enableNotifications() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/enablenotifications", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Notification has been enabled for user ${username}`,
        })
      );
      return dispatch({
        type: ENABLENOTIFICATIONS_USER,
      });
    });
  };
}

export function disableNotifications() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/disablenotifications", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Notification has been disabled for user ${username}`,
        })
      );
      return dispatch({
        type: DISABLENOTIFICATIONS_USER,
      });
    });
  };
}

export function fraudChecked(valid) {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/fraudchecked", {
      userid,
      valid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({ message: `Fraud for ${username} has been checked` })
      );
      return dispatch({
        type: FRAUDCHECKED_USER,
      });
    });
  };
}

export function unarchive() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/unarchive", {
      userid,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `User ${username} has been scheduled to unarchive`,
        })
      );
      return dispatch({
        type: UNARCHIVE_USER,
      });
    });
  };
}

export function resetSort() {
  return (dispatch) => {
    return dispatch({
      type: RESET_USER_SORT,
    });
  };
}

export function selectUser(id) {
  return (dispatch) => {
    dispatch({
      type: SELECT_USER,
      payload: { id },
    });
    return dispatch(fetchUserProfile());
  };
}

export function setUserTab(tabid) {
  return {
    type: SET_USER_TAB,
    payload: { tabid },
  };
}

export function fetchUserProfile() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user", {
      userid,
    });
    return request.then((response) =>
      dispatch({
        type: FETCH_USER_PROFILE,
        payload: { ...response.data },
      })
    );
  };
}

export function fetchUserSort(id) {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/sort", {
      userid: id ?? userid,
    });
    return request.then((response) =>
      dispatch({
        type: FETCH_USER_SORT,
        payload: { ...response.data },
      })
    );
  };
}

export function fetchUserFace() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/face", {
      userid,
    });
    return request.then((response) =>
      dispatch({
        type: FETCH_USER_FACE,
        payload: { ...response.data },
      })
    );
  };
}

export function approveFace() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/face/approve", {
      userid,
    });
    return request
      .then((response) => {
        dispatch(showMessage({ message: `Face has been approved` }));
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      });
  };
}

export function addToFraudlistUser() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/face/addtofraudlist", {
      userid,
    });
    return request
      .then((response) => {
        dispatch(showMessage({ message: `Face has been added to FraudList` }));
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      });
  };
}

export function removeFromFraudlistUser() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/face/removefromfraudlist", {
      userid,
    });
    return request
      .then((response) => {
        dispatch(
          showMessage({ message: `Face has been removed from FraudList` })
        );
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      });
  };
}

export function removeFrom3DDB() {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/face/delete", {
      userid,
    });
    return request
      .then((response) => {
        dispatch(showMessage({ message: `Face has been deleted` }));
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        dispatch(fetchUserFace());
        return dispatch(fetchUserProfile());
      });
  };
}

export function addIsp(values) {
  return (dispatch, getState) => {
    const request = axios.post("/api/isp/all/add", values);
    return request
      .then((response) => {
        dispatch(showMessage({ message: `ISP has been saved` }));
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        return dispatch(fetchUserProfile());
      });
  };
}

export function editIsp(values) {
  return (dispatch, getState) => {
    const request = axios.post("/api/isp/all/editbyname", values);
    return request
      .then((response) => {
        dispatch(showMessage({ message: `ISP has been changed` }));
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        return dispatch(fetchUserProfile());
      });
  };
}

export function deleteIsp(values) {
  return (dispatch, getState) => {
    const request = axios.post("/api/isp/all/deletebyname", values);
    return request
      .then((response) => {
        dispatch(showMessage({ message: `ISP has been deleted` }));
        return dispatch(fetchUserProfile());
      })
      .catch((e) => {
        dispatch(
          showMessage({
            message: e.response.data.errors.message,
            type: "error",
          })
        );
        return dispatch(fetchUserProfile());
      });
  };
}

export function changePassword(password) {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/password", {
      userid,
      password,
    });

    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Password has been reset`,
        })
      );
      return dispatch({
        type: ENABLENOTIFICATIONS_USER,
      });
    });
  };
}
export function changeInviteBonus(bonusValue) {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/editinvitebonus", {
      userid,
      bonusValue,
    });
    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Invite bonus value was changed`,
        })
      );
    });
  };
}
export function changeInviteLink(linkValue) {
  return (dispatch, getState) => {
    const {
      table: {
        user: { userid },
      },
    } = getState();
    const request = axios.post("/api/user/editinvitelink", {
      userid,
      linkValue,
    });
    return request.then((response) => {
      if (response.data.error) {
        dispatch(
          showMessage({
            message: response.data.error,
            type: "error",
          })
        );
      } else {
        dispatch(
          showMessage({
            message: `Invite link was changed`,
          })
        );
      }
    });
  };
}

export function exportUnits() {
  return (dispatch, getState) => {
    const {
      table: {
        user: {
          userid,
          profile: { username },
        },
      },
    } = getState();
    const request = axios.post("/api/user/exportunits", {
      userid,
    });

    return request.then((response) => {
      dispatch(
        showMessage({
          message: `Units Exported!`,
        })
      );
      csvDownload({
        data: response.data,
        filename: `${username}-Unitslogs.csv`,
      });
    });
  };
}

export function selectReward(row) {
  return (dispatch) => {
    dispatch({
      type: SELECT_REWARD,
      payload: row,
    });
  };
}
