import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";
import axios from "axios";
export const GET_GAMESTATS = "[GAMESTATS] GET GAMESTATS";
export const SET_LOADING = "[GAMESTATS] SET LOADING";
export const SET_GAMESTATS = "[GAMESTATS] SET GAMESTATS";
export const SET_DATERANGE = "[GAMESTATS] SET DATERANGE";
export const SET_GROUPBY = "[GAMESTATS] SET GROUPBY";
export const FETCH_GAME = "[GAME] FETCH GAME";
export const FETCH_PURCHASES = "[PURCHASES] FETCH PURCHASES";
function showError(err, dispatch) {
  if (err.response.data.error) {
    dispatch(showMessage({ message: err.response.data.error, type: "warn" }));
  }
}
export function setDateRange(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch(setLoading(true));
    dispatch(getStats());
  };
}

export function getDataOnApply(startDate, endDate, groupBy) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch({
      type: SET_GROUPBY,
      payload: { groupBy },
    });
    dispatch(setLoading(true));
    dispatch(getStats());
  };
}

export function fetchPurchases(gameid, startDate, endDate) {
  return (dispatch) => {
    const request = axios.post(`/api/stats/game/purchases`, {
      gameid,
      startDate,
      endDate,
    });
    return request
      .then((response) =>
        dispatch({
          type: FETCH_PURCHASES,
          payload: { purchases: response.data },
        })
      )
      .catch((err) => {
        showError(err, dispatch);
      });
  };
}
export function fetchGame(gameid) {
  return (dispatch) => {
    const request = axios.post(`/api/game`, {
      gameid,
    });
    return request
      .then((response) =>
        dispatch({
          type: FETCH_GAME,
          payload: { game: response.data },
        })
      )
      .catch((err) => {
        showError(err, dispatch);
      });
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };
}
export function getStats() {
  return (dispatch, getState) => {
    const {
      gamestats: {
        gamestats: { startDate, endDate, groupBy },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day"),
      endDate: endDate.utc().startOf("day"),
      groupBy: groupBy,
    };
    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_GAMESTATS_REQUEST", {
        data: { params: { ...dates } },
        method: "post",
        url: "/api/stats/gamesstats",
      })
    ).then((response) => {
      if (response.data.error) {
        dispatch(showMessage({ message: response.data.error, type: "error" }));
        return;
      }
      if (response.data.gamestats[0]?.created_at_date) {
        response.data.gamestats = response.data.gamestats.map((r) => {
          r.created_at_date = r.created_at_date?.value;
          return r;
        });
      }
      dispatch({
        type: GET_GAMESTATS,
        payload: response.data,
      });
    });
  };
}
