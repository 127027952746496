import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";

import PropTypes from "prop-types";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class ProfitMediaSource extends Component {
  render() {
    const { data, loading, groupBy } = this.props;

    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm" style={{ textAlign: "right" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>
                  {groupBy === 1
                    ? "Date"
                    : groupBy === 2
                    ? "Country"
                    : "MediaSource"}
                </th>
                <th width="100px">Users</th>
                <th width="200px">Revenue ($)</th>
                <th width="120px">Revenue (%)</th>
                <th width="180px">Cost ($)</th>
                <th width="100px">Cost (%)</th>
                <th width="220px">Cost Rewards ($)</th>
                <th width="140px">Cost Rewards (%)</th>
                <th width="180px">Profit ($)</th>
                <th width="100px">Profit (%)</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((bucketKey, index) => {
                const d = data[bucketKey];
                const count = d.length;

                return d.map((row, i) => {
                  const {
                    users,
                    revenue,
                    revenuePerc,
                    cost,
                    costPerc,
                    rewardscost,
                    rewardscostPerc,
                    profit,
                    profitPerc,
                  } = row;

                  return (
                    <tr
                      key={`${bucketKey}-${i}`}
                      style={index === data ? { fontWeight: "bold" } : {}}
                    >
                      {i === 0 ? (
                        <td rowSpan={count} style={{ textAlign: "left" }}>
                          <b>{bucketKey}</b>
                        </td>
                      ) : null}

                      <td>{users.toFixed(0)}</td>
                      <td>{revenue.toFixed(1)}</td>
                      <td>{revenuePerc.toFixed(1)}</td>
                      <td>{cost.toFixed(1)}</td>
                      <td>{costPerc.toFixed(1)}</td>
                      <td>{rewardscost.toFixed(1)}</td>
                      <td>{rewardscostPerc.toFixed(1)}</td>
                      <td>{profit.toFixed(1)}</td>
                      <td>{profitPerc.toFixed(1)}</td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

ProfitMediaSource.propTypes = propTypes;

export default ProfitMediaSource;
