import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";
import TableRow from "./CountryTableRow";
import PropTypes from "prop-types";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class ProfitCountryMediaSource extends Component {
  render() {
    const { data, loading } = this.props;
    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm" style={{ textAlign: "right" }} hover>
            <thead style={{ backgroundColor: "#f2f2f2" }}>
              <tr>
                <th style={{ textAlign: "left" }}>Country</th>
                <th width="100px">Users</th>
                <th width="200px">Revenue ($)</th>
                <th width="120px">Revenue (%)</th>
                <th width="180px">Cost ($)</th>
                <th width="100px">Cost (%)</th>
                <th width="220px">Cost Rewards ($)</th>
                <th width="140px">Cost Rewards (%)</th>
                <th width="180px">Profit ($)</th>
                <th width="100px">Profit (%)</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data).map((key) => {
                return <TableRow key={key} country={key} details={data[key]} />;
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

ProfitCountryMediaSource.propTypes = propTypes;

export default ProfitCountryMediaSource;
