import React from "react";
import GameStats from "./GameStats";
import { authRoles } from "app/auth";

export const GameStatsConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/gamestats/:id",
      component: React.lazy(() => import("./game/Game")),
      name: "More Info",
    },
    {
      path: "/gamestats",
      component: GameStats,
      name: "Game Stats",
    },
  ],
};
