import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";
import _ from "lodash";

export const GET_METRICS = "[METRICS] GET METRICS";
export const SET_LOADING = "[METRICS] SET LOADING";
export const SET_DATERANGE = "[METRICS] SET DATERANGE";

export function setDateRange(
  userAgeType,
  startDate,
  endDate,
  startDateAge,
  endDateAge
) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate, startDateAge, endDateAge },
    });
    dispatch(getDauMetrics(userAgeType));
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };
}

export function getDauMetrics(userAgeType) {
  return (dispatch, getState) => {
    const {
      metrics: {
        metrics: { startDate, endDate, startDateAge, endDateAge },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }

    if ((startDateAge === null || endDateAge === null) && userAgeType === 9) {
      return;
    }

    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
      startDateAge: startDateAge?.utc()?.startOf("day")?.unix(),
      endDateAge: endDateAge?.utc()?.startOf("day")?.unix(),
    };

    const params = { ...dates };

    if (userAgeType !== undefined && userAgeType !== "") {
      params.userAgeType = userAgeType;
    }

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_METRICS_REQUEST", {
        params,
        method: "get",
        url: "/api/stats/metricsdau",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }
        const totals = _.chain(response.data)
          .remove((obj) => obj.created_at_date === "TOTALS")
          .head()
          .value();
        if (response.data?.[0]?.created_at_date) {
          response.data = response.data.map((r) => {
            r.created_at_date = r.created_at_date?.value || r.created_at_date;
            return r;
          });
        }

        dispatch({
          type: GET_METRICS,
          payload: { data: response.data, totals },
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
