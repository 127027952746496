import * as Actions from "../actions";

import moment from "moment";

const initialState = {
  loading: false,
  data: { gamestats: [] },
  startDate: moment().subtract(7, "d"),
  endDate: moment(),
  groupBy: ["game_name", "created_at_date"],
};

const gamestatsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.FETCH_PURCHASES: {
      const {
        payload: { purchases },
      } = action;
      return {
        ...state,
        purchases,
      };
    }
    case Actions.FETCH_GAME: {
      const {
        payload: { startDate, endDate, game },
      } = action;
      return {
        ...state,
        game,
        startDate,
        endDate,
      };
    }
    case Actions.SET_DATERANGE: {
      const {
        payload: { startDate, endDate },
      } = action;
      return {
        ...state,
        startDate,
        endDate,
      };
    }
    case Actions.SET_GROUPBY: {
      const {
        payload: { groupBy },
      } = action;
      return {
        ...state,
        groupBy,
      };
    }
    case Actions.GET_GAMESTATS: {
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
        },
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default gamestatsReducer;
