import { showMessage } from "app/store/actions";
import axios from "axios";

export const SCORE_FETCH = "[SCORE] FETCH";
export const SCORE_LOADING = "[SCORE] LOADING";

export function showMessageAction(message) {
  return (dispatch) => {
    dispatch(showMessage(message));
  };
}

const loadingStart = (dispatch) => {
  dispatch({
    type: SCORE_LOADING,
    payload: true,
  });
};
const loadingStop = (dispatch) => {
  dispatch({
    type: SCORE_LOADING,
    payload: false,
  });
};

export function getUsersScore() {
  return (dispatch) => {
    const request = axios.get(`/api/users/score`);
    loadingStart(dispatch);
    return request.then(
      (response) => {
        loadingStop(dispatch);
        dispatch({
          type: SCORE_FETCH,
          payload: response.data,
        });
      },
      (error) => {
        loadingStop(dispatch);
        dispatch(
          showMessageAction({
            message: error.message
              ? error.message
              : "Error fetching users score",
            type: "error",
          })
        );
      }
    );
  };
}
