import React from "react";
import { authRoles } from "app/auth";

export const ChestsConfig = {
  auth: [...authRoles.admin, ...authRoles.chestsViewer],
  routes: [
    {
      path: "/chests/setting",
      component: React.lazy(() => import("./chests/Chests")),
      name: "Chests",
    },
    {
      path: "/chests/open",
      component: React.lazy(() => import("./openChests/OpenChests")),
      name: "Open Chests",
    },
  ],
};
