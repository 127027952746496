import * as Actions from "../actions";

import moment from "moment";

const initialState = {
  loading: false,
  data: [],
  totals: null,
  startDate: moment().subtract(7, "d"),
  endDate: moment(),
  startDateAge: null,
  endDateAge: null,
};

const metricsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_DATERANGE: {
      const {
        payload: { startDate, endDate, startDateAge, endDateAge },
      } = action;
      return {
        ...state,
        startDate,
        endDate,
        startDateAge,
        endDateAge,
      };
    }

    case Actions.GET_METRICS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        totals: { ...action.payload.totals },
      };
    }
    case Actions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default metricsReducer;
