import { apiSagaRequest } from "app/store/actions";
import axios from "axios";

export const FETCH = "[TABLE] FETCH";
export const CLEAN_ALL = "[TABLE] CLEAN ALL";
export const SET_FILTER = "[TABLE] SET FILTER";
export const SET_FILTER_INIT = "[TABLE] SET FILTER INIT";
export const SET_SORT = "[TABLE] SET SORT";
export const SET_PAGING = "[TABLE] SET PAGING";
export const SET_UNITS = "[TABLE] SET UNITS";
export const SET_LOADING = "[TABLE] SET LOADING";

export const SET_SELECTED_DAU_USERS = "SET_SELECTED_DAU_USERS";
export const CLEAR_SELECTED_DAU_USERS = "CLEAR_SELECTED_DAU_USERS";

export function setPaging(type, page, sizePerPage) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { page, sizePerPage, subtype: SET_PAGING },
    });
    dispatch(fetch(type));
  };
}

export function setSelectedDauUsers(data) {
  return {
    type: "users", // This needs to be the table name
    payload: {
      subtype: SET_SELECTED_DAU_USERS,
      ...data,
    },
  };
}

export function clearSelectedDauUsers() {
  return {
    type: "users", // This needs to be the table name
    payload: {
      subtype: CLEAR_SELECTED_DAU_USERS,
    },
  };
}

export function setUnitsFilter(type) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: {
        units: { value: { number: 0, comparator: ">" }, type: "NumberFilter" },
        subtype: SET_UNITS,
      },
    });
    dispatch(fetch(type));
  };
}

export function setFilter(type, filter) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { ...filter, subtype: SET_FILTER },
    });
    dispatch(fetch(type));
  };
}

export function setSort(type, sortName, sortOrder) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { sortName, sortOrder, subtype: SET_SORT },
    });
    dispatch(fetch(type));
  };
}

export function setFilterInit(type, filter) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { ...filter, subtype: SET_FILTER_INIT },
    });
    dispatch(fetch(type));
  };
}

export function clean(type) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { subtype: CLEAN_ALL },
    });
  };
}

export function setLoading(type, value) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: { value, subtype: SET_LOADING },
    });
  };
}

export function filterLog(type, filter) {
  let userId = filter["acc_id"].value;
  let stringToSearch = filter.value.value || "";

  return (dispatch) => {
    const data = {
      userid: userId,
      value: stringToSearch,
    };
    const request = axios.post("/api/" + type + "/search", {
      data,
    });

    return request.then((response) => {
      console.log("[info] response user: ", response.data);
      response.data.data = response.data.data.map((item, index) => ({
        ...item,
        id: `${userId}-${index}`,
        created_at: item.created_at.value,
      }));
      dispatch({
        type: type,
        payload: { ...response.data, subtype: FETCH },
      });
    });
  };
}

export function fetch(type) {
  return (dispatch, getState) => {
    const {
      table: {
        table: {
          [type]: { table },
        },
      },
    } = getState();
    dispatch(setLoading(type, true));
    return dispatch(
      apiSagaRequest("TABLE_REQUEST", {
        method: "post",
        url: "/api/" + type,
        data: { ...table },
      })
    ).then((response) => {
      console.log("[info] response user: ", response.data);
      response.data.data = response.data.data.map((item) => ({
        ...item,
        name: item.game?.name || "",
      }));
      dispatch({
        type: type,
        payload: { ...response.data, subtype: FETCH },
      });
    });
  };
}
